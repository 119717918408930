<template>
    <PageLoader v-if="this.Store.isLoading" />
    <div v-else>
        <div v-if="this.Store.getisLogged == 'none' || this.$route.path == '/'" id="app">
            <div v-if="this.screenWidth > 992">
                <NavBar :userLogged="userLogged" :toggleSearch="this.toggleSearch" ref="navbar" />
            </div>
            <div v-else>
                <PhoneNavBar :userLogged="userLogged" :toggleSearch="this.toggleSearch" />
            </div>
            <!-- <SwitchLocale /> -->
            <!-- <TopBar :open="togglebtn" @Opennav="(navOpen = true), (togglebtn = false)" /> -->
            <SideBar :open="navOpen" @Closenav="(navOpen = false), (togglebtn = true)" />

            <searchBar :search="this.search" ref="searchBarContainer" />
            <router-view />
            <!-- <footerPage /> -->
            <FooterComponent />
            <!-- <NewFooter /> -->
        </div>
        <div v-if="this.Store.getisLogged == 'user'" class="logged">
            <SubscriptionComponent :show="showPlans" :togglePlans="togglePlans" />
            <SideBarOnBarding />
            <!-- <WelcomeWindow
            :startTour="startTour"  
            :showWelcome="showWelcome"
            :toggleWelcome="toggleWelcome"
        /> -->

            <!-- <GuidesWindow /> -->
            <NavbarView :SidebarViewOpen="this.SidebarViewOpen" />
            <div class="layout w-[90%] mx-[5%] h-full bg-[#f4f7fe] p-2 rounded-md">
                <!-- <SidebarView :toggleSideBarView="this.toggleSideBarView" :SidebarViewOpen="this.SidebarViewOpen" :togglePlans="togglePlans" v-if="this.$route.path !== '/newAssessment'" /> -->
                <!-- <NavigationView /> -->
                <div class="router-view h-full" :class="{ expend: !SidebarViewOpen }">
                    <NavgationTabsView v-if="this.$route.path == '/Manage' || this.$route.path == '/refferal' || this.$route.path == '/archive' || this.$route.path == '/invoice'" />
                    <router-view />
                </div>
                <FeedBack />
            </div>
            <!-- <ChatRoom /> -->
            <!-- <v-tour name="myTour" :steps="steps"></v-tour> -->
            <!-- :class="{ tourSteps: takeTour }" -->
        </div>
    </div>
    <div v-if="this.Store.getisLogged == 'admin'" class="logged">
        <NavbarView :toggleSideBarView="this.toggleSideBarView" />
        <div class="layout w-full">
            <SidebarView :SidebarViewOpen="this.SidebarViewOpen" :togglePlans="togglePlans" />
            <!-- <NavigationView /> -->
            <div class="ml-[18%] w-full" :class="{ expend: !SidebarViewOpen }">
                <NavgationTabsView v-if="this.$route.path == '/Manage' || this.$route.path == '/refferal' || this.$route.path == '/archive' || this.$route.path == '/invoice'" />
                <router-view />
            </div>
        </div>
        <!-- <ChatRoom /> -->
        <!-- <v-tour name="myTour" :steps="steps"></v-tour> -->
        <!-- :class="{ tourSteps: takeTour }" -->
    </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import PhoneNavBar from "./components/PhoneNavBar.vue";
// import footerPage from "./components/footerPage.vue";
import FooterComponent from "./components/FooterComponent.vue";
import searchBar from "./components/searchBar.vue";
import SideBar from "./components/sideBar.vue";
// import TopBar from "./components/TopBar.vue";
import NavbarView from "./components/NavbarView.vue";
// import UpgradeBtn from "./components/UpgradeBtn.vue";
import NavgationTabsView from "./components/NavgationTabsView.vue";
// import NavigationView from "./components/NavigationView.vue";
import SidebarView from "./components/SidebarView.vue";
import SubscriptionComponent from "./components/Subscription.vue";
// import WelcomeWindow from "./components/WelcomeWindow.vue";
import { useStore } from "./store/index";
import SideBarOnBarding from "./components/SideBarOnBarding.vue";
// import GuidesWindow from "./components/GuidesWindow.vue";
import FeedBack from "./components/FeedBack.vue";
// import SwitchLocale from "./components/SwitchLocale.vue";
// import ChatRoom from "./views/ChatRoom.vue";
// import LoadingComponent from "./components/LoadingComponent.vue";
import PageLoader from "./components/PageLoader.vue";
// import NewFooter from "./components/LandingPage/NewFooter.vue";

export default {
    name: "App",
    components: {
        NavBar,
        PhoneNavBar,
        // footerPage,
        FooterComponent,
        // NewFooter,
        searchBar,
        // TopBar,
        SideBar,
        NavbarView,
        SidebarView,
        SubscriptionComponent,
        // NavigationView,
        NavgationTabsView,
        // ChatRoom,
        // WelcomeWindow,
        SideBarOnBarding,
        // GuidesWindow,
        // SwitchLocale,
        FeedBack,
        // LoadingComponent,
        PageLoader,
    },
    data() {
        return {
            isLoading: true,
            togglebtn: true,
            SidebarViewOpen: true,
            navOpen: false,
            search: false,
            screenWidth: window.innerWidth,
            showPlans: false,
            visibleSlide: 0,
            showWelcome: true,
            takeTour: false,
            categories: [
                {
                    path: "assets/marketing.png",
                    title: "Marketing & Communication",
                    text: "237 Assessement Available",
                },
                {
                    path: "assets/design.png",
                    title: "Design & Developpement",
                    text: "145 Assessement Available",
                },
                {
                    path: "assets/front.png",
                    title: "Front-end Developement",
                    text: "237 Assessement Available",
                },
                {
                    path: "assets/back.png",
                    title: "Back-end Developement",
                    text: "248 Assessement Available",
                },
                {
                    path: "assets/app.png",
                    title: "App developement",
                    text: "237 Assessement Available",
                },
                {
                    path: "assets/security.png",
                    title: "Security & Network",
                    text: "237 Assessement Available",
                },
            ],
            steps: [
                {
                    target: "#library", // We're using document.querySelector() under the hood
                    header: {
                        title: "Get Started",
                    },
                    content: `<div>
            <h1>Welcome to My Tour!</h1>
            <img loading="lazy"  decoding="async" src="./assets/Images/Landing_page.png" alt="Tour Image" />
            <p>This is a description of the tour step.</p>
          </div>`,
                    next: () => {
                        console.log("Welcome to My Tour");
                        this.$router.push("/library");
                    },
                },
                {
                    target: "#library", // We're using document.querySelector() under the hood
                    header: {
                        title: "Get Started",
                    },
                    content: `<div>
            <h1>Welcome to My Tour!</h1>
            <img loading="lazy"  decoding="async" src="./assets/Images/Landing_page.png" alt="Tour Image" />
            <p>This is a description of the tour step.</p>
          </div>`,
                    next: () => {
                        console.log("Welcome to My Tour");
                        this.$router.push("/library");
                    },
                },
                {
                    target: "#library", // We're using document.querySelector() under the hood
                    header: {
                        title: "Get Started",
                    },
                    content: `<div>
            <h1>Welcome to My Tour!</h1>
            <img loading="lazy"  decoding="async" src="./assets/Images/Landing_page.png" alt="Tour Image" />
            <p>This is a description of the tour step.</p>
          </div>`,
                    next: () => {
                        console.log("Welcome to My Tour");
                        this.$router.push("/library");
                    },
                },
                {
                    target: ".v-step-1",
                    content: "An awesome plugin made with Vue.js!",
                },
                {
                    target: '[data-v-step="2"]',
                    content: "Try it, you'll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.",
                    params: {
                        placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                    },
                },
            ],
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        toggleSearch() {
            this.search = !this.search;
            console.log("search icon clicked", this.search);
            if (this.search) {
                document.addEventListener("click", this.clickOutside);
            } else {
                document.removeEventListener("click", this.clickOutside);
            }
        },
        toggleSideBarView() {
            this.SidebarViewOpen = !this.SidebarViewOpen;
        },
        startTour() {
            this.takeTour = true;
            this.showWelcome = false;
            this.$tours["myTour"]?.start();
        },
        toggleWelcome() {
            this.showWelcome = !this.showWelcome;
        },
        navigateToNextStep() {
            this.takeTour = false;
            this.$router.push("/library");
        },
        clickOutside(event) {
            if (!this.$refs.navbar.$refs.searchIcon.contains(event.target) && !this.$refs.searchBarContainer.$refs.searchInput.contains(event.target)) {
                console.log("clicked outside search", event.target);
                this.search = false;
                document.removeEventListener("click", this.clickOutside);
            }
        },
    },

    mounted() {
        //console.log(sessionStorage.getItem('user'))
        // Check if there is a valid login token in local storage
        this.Store.userLogged();
        // console.log(this.loggedIn);
        const loginToken = localStorage.getItem("LoginToken");

        if (loginToken) {
            // Retrieve user information from session storage
            const userString = sessionStorage.getItem("user");
            if (userString) {
                const user = JSON.parse(userString);
                const { firstName, lastName } = user;
                this.$root.userInitials = (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
            }
        }
        this.Store.getCompanyCredit();
    },
    provide() {
        return {
            userInitials: this.userInitials,
        };
    },
};
</script>

<style scoped lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    justify-items: center;
    /* width: 100vw; */
    /* height: 350vh;
  grid-template-rows: 5% 20% 20% 41%  7% 7%;
  display: grid;
  color: #2c3e50;*/
}

.layout {
    display: flex;
    align-items: stretch;
    // width: 100%;
    margin-top: 4rem;
}

.router-view {
    /* width: 70%; */
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    flex-grow: 1;
    // padding-left: 2rem;
    width: 80%;
    transition: all 0.3 ease-in-out;

    .container {
        min-height: 75vh;
        min-width: 100%;
    }
}

.tourSteps {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;

    > :first-child {
        background-color: red;
    }

    > :nth-child(2) {
        background-color: #fff;
    }

    #header {
        background-color: red;
    }
}

body::after {
    content: "";
    /* background: url("./assets/Images/world_map2.svg"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.7;
    filter: grayscale(0%) brightness(100%) blur(2px) hue-rotate(30deg);
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    justify-items: center;
    /* width: 100%;
  height: 350vh;
  grid-template-rows: 5% 20% 20% 41%  7% 7%;
  display: grid;
  color: #2c3e50;*/
}

.layout {
    /* display: grid;
  grid-template-columns: 1fr 1fr 6fr; */
}

.expend {
    margin-left: 5%;
}
</style>
